import MmenuLight from "../../node_modules/mmenu-light/src/mmenu-light.js";
import bodymovin from "lottie-web";
import ScrollOut from "scroll-out";

// import Vue from 'vue';
// import Vue from 'vue/dis/vue.js';

document.addEventListener("DOMContentLoaded", () => {
  // Mobile menu init // Sync search and menu open
  const menu = new MmenuLight(
    document.querySelector("#mobile-menu"),
    "(max-width: 1024px)"
  );
  const navigator = menu.navigation();
  const drawer = menu.offcanvas();

  //Open Menu
  const navTrigger = document.querySelector(".js-open-nav");
  const projectWrapper = document.getElementById("app");

  //Toggle Menu
  bindEvent(navTrigger, "click", function () {
    this.classList.toggle("is-active");
    projectWrapper.classList.toggle("js-is-open");

    if (this.classList.contains("is-active")) {
      drawer.open();
    } else {
      drawer.close();
    }
  });

  const iFrame = document.querySelectorAll('.wysiwyg iframe')

    iFrame.forEach(item => {  
        const wrapper = document.createElement("div");
        item.parentNode.insertBefore(wrapper, item);
        wrapper.appendChild(item);

        item.parentNode.classList.add('video-wrapper');
    });

  const lottieLogoWhite = document.getElementById("lottie-logo-white");

  if (lottieLogoWhite) {
    const animation = bodymovin.loadAnimation({
      container: lottieLogoWhite,
      path: "/lottie/Logo_Dark_v2.json",
      renderer: "svg",
      loop: false,
      autoplay: false,
    });

    lottieLogoWhite.addEventListener("mouseenter", function (e) {
      e.preventDefault();
      animation.goToAndPlay(1, true);
    });
  }

  const lottieLogoDark = document.getElementById("lottie-logo-dark");

  console.log(lottieLogoDark);

  if (lottieLogoDark) {
    const animation2 = bodymovin.loadAnimation({
      container: lottieLogoDark,
      path: "/lottie/Logo_Light_v2.json",
      renderer: "svg",
      loop: false,
      autoplay: false,
    });

    lottieLogoDark.addEventListener("mouseenter", function (e) {
      e.preventDefault();
      animation2.goToAndPlay(1, true);
    });
  }

  const lottieLogoHomeBanner = document.getElementById("lottie-home-banner");

  if (lottieLogoHomeBanner) {
    const animation3 = bodymovin.loadAnimation({
      container: lottieLogoHomeBanner,
      path: "/lottie/Banner_v2.json",
      renderer: "svg",
      loop: true,
      autoplay: false,
    });

    ScrollOut({
      onShown: function (lottieLogoHomeBanner) {
        animation3.play();
      },
    });
  }

  const lottieSolutions = document.getElementById("solutions-lottie");

  if (lottieSolutions) {
    const animation4 = bodymovin.loadAnimation({
      container: lottieSolutions,
      path: "/lottie/Rings_v3.json",
      renderer: "svg",
      loop: true,
      autoplay: false,
    });

    ScrollOut({
      onShown: function (lottieLogoHomeBanner) {
        animation4.play();
      },
    });
  }

  const lottieGlobe = document.getElementById("globe-lottie");

  if (lottieGlobe) {
    const animation5 = bodymovin.loadAnimation({
      container: lottieGlobe,
      path: "/lottie/Globe_v3.json",
      renderer: "svg",
      loop: true,
      autoplay: false,
    });

    ScrollOut({
      onShown: function (lottieGlobe) {
        animation5.play();
      },
    });
  }

  const lottieStatement = document.getElementById("statement-lottie");

  if (lottieStatement) {
    const animation6 = bodymovin.loadAnimation({
      container: lottieStatement,
      path: "/lottie/Repeating_Circle.json",
      renderer: "svg",
      loop: true,
      autoplay: false,
    });

    ScrollOut({
      onShown: function (lottieStatement) {
        animation6.play();
      },
    });
  }

  const lottieStatementGrid = document.getElementById("statement-lottie-grid");

  if (lottieStatementGrid) {
    const animationGrid = bodymovin.loadAnimation({
      container: lottieStatementGrid,
      path: "/lottie/Grid_Circle_v2.json",
      renderer: "svg",
      loop: true,
      autoplay: false,
    });

    ScrollOut({
      onShown: function (lottieStatement) {
        animationGrid.play();
      },
    });
  }

  const oneColRight = document.getElementById("one-col-lottie-right");

  if (oneColRight) {
    const animation7 = bodymovin.loadAnimation({
      container: oneColRight,
      path: "/lottie/Quarter_Circle_v2.json",
      renderer: "svg",
      loop: true,
      autoplay: false,
    });

    ScrollOut({
      onShown: function (lottieStatement) {
        animation7.play();
      },
    });
  }

  const oneColLeft = document.getElementById("one-col-lottie-left");

  if (oneColLeft) {
    const animation8 = bodymovin.loadAnimation({
      container: oneColLeft,
      path: "/lottie/Half_Circle_v2.json",
      renderer: "svg",
      loop: true,
      autoplay: false,
    });

    ScrollOut({
      onShown: function (lottieStatement) {
        animation8.play();
      },
    });
  }

  // Bind event
  function bindEvent(el, eventName, eventHandler) {
    if (el.addEventListener) {
      el.addEventListener(eventName, eventHandler, false);
    } else if (el.attachEvent) {
      el.attachEvent("on" + eventName, eventHandler);
    }
  }
});
